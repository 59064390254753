<template>
  <div>
    <v-toolbar color="amber lighten-1">
      <v-toolbar-side-icon></v-toolbar-side-icon>
      <v-toolbar-title class="headline"
        >72 ล้านต้น พลิกฟื้นผืนป่า</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!-- <v-btn
          flat
          @click="gotoLink('/report_studentloan')"
          v-if="permission === '2' || permission === '0'"
        >
          <v-icon class="mr-2">library_books</v-icon>Report For Student Loan
        </v-btn> -->
        <v-btn
          flat
          @click="gotoLink('/report')"
          v-if="permission === '1' || permission === '0'"
        >
          <v-icon class="mr-2">search</v-icon>ค้นหาข้อมูล
        </v-btn>
        <v-btn
          flat
          @click="gotoLink('/report_nursery')"
          v-if="permission === '1' || permission === '0'"
        >
          <v-icon class="mr-2">library_books</v-icon>สถิติ
        </v-btn>
        <!-- <v-btn
          flat
          @click="gotoLink('/edit_addplant')"
          v-if="permission === '1' || permission === '0'"
        >
          <v-icon class="mr-2">edit</v-icon>ตรวจสอบการบันทึกต้นไม้
        </v-btn> -->
        <!-- <v-btn flat>
          <v-icon class="mr-2">group</v-icon>จัดการ Admin
        </v-btn> -->
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn flat v-on="on">
              สวัสดีคุณ {{ admin_name }}
              <v-icon>keyboard_arrow_down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile
              v-for="(item, index) in items"
              :key="index"
              @click="gotoLink(item.link)"
            >
              <v-list-tile-title
                >{{ item.title }}<v-icon class="ml-2">{{ item.icon }}</v-icon>
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    <v-content>
      <v-container pa-0 justify-center align-center flex fill-height>
        <v-layout justify-center align-center fill-height>
          <router-view></router-view>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      items: [{ title: "ออกจากระบบ", link: "/logout", icon: "logout" }],
      admin_name: "",
      department_name: "",
    };
  },
  created() {
    var admin_name = Decode.decode(this.$cookies.get("admin_name"));
    this.admin_name = JSON.parse(admin_name).name;
    var permission = Decode.decode(this.$cookies.get("permission"));
    this.permission = JSON.parse(permission).permission;
    //(this.permission);
  },
  methods: {
    gotoLink(path) {
      // console.log(path);
      if (path === "/logout") {
        this.$cookies.remove("admin_name", "/", process.env.VUE_APP_WEB);
        this.$cookies.remove("permission", "/", process.env.VUE_APP_WEB);
        this.$router.push("/admin-login");
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped></style>
