import { render, staticRenderFns } from "./MainAdminPage.vue?vue&type=template&id=32a3abf1&scoped=true&"
import script from "./MainAdminPage.vue?vue&type=script&lang=js&"
export * from "./MainAdminPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32a3abf1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/builds/rfd/seeding_72/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VMenu } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarItems } from 'vuetify/lib'
import { VToolbarSideIcon } from 'vuetify/lib'
import { VToolbarTitle } from 'vuetify/lib'
installComponents(component, {VBtn,VContainer,VContent,VIcon,VLayout,VList,VListTile,VListTileTitle,VMenu,VSpacer,VToolbar,VToolbarItems,VToolbarSideIcon,VToolbarTitle})
